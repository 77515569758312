import Vue, { PluginObject } from 'vue'
import Editor from '@/components/Editor/Editor.vue'
import UrlCodeTextarea from '@/components/UrlCodeTextarea/UrlCodeTextarea.vue'
import FilteredSearchBlock from '@/components/FilteredSearchBlock/FilteredSearchBlock.vue'
import TableFooter from '@/components/TableFooter/TableFooter.vue'
import DataTable from '@/components/DataTable/DataTable.vue'

export const AppComponentsPlugin: PluginObject<undefined> = {
  install() {
    const components = {
      Editor,
      UrlCodeTextarea,
      FilteredSearchBlock,
      TableFooter,
      DataTable,
      ImageCrop: () => import('@/components/ImageCrop/ImageCrop.vue'),
      ImageUi: () => import('@/components/ImageUi/ImageUi.vue'),
      CopyTextMenu: () => import('@/components/CopyTextMenu/CopyTextMenu.vue'),
      ImageSourceCombobox: () =>
        import('@/components/ImageSourceCombobox/ImageSourceCombobox.vue'),
      ConfirmDeleteDialog: () =>
        import('@/components/ConfirmDeleteDialog/ConfirmDeleteDialog.vue'),
      CounterValue: () => import('@/components/CounterValue/CounterValue.vue'),
      ExpandMultiselect: () =>
        import('@/components/ExpandMultiselect/ExpandMultiselect.vue'),
      DraggableImagesPanel: () =>
        import(
          '@/components/DraggableImages/DraggableImagesPanel/DraggableImagesPanel.vue'
        ),
      DraggableImages: () =>
        import('@/components/DraggableImages/DraggableImages.vue'),
      DraggableImagesContainer: () =>
        import(
          '@/components/DraggableImages/DraggableImagesContainer/DraggableImagesContainer.vue'
        ),
      DropZone: () => import('@/components/DropZone/DropZone.vue'),
      ImageUploader: () =>
        import('@/components/ImageUploader/ImageUploader.vue'),
      ImageCropper: () => import('@/components/ImageCropper/ImageCropper.vue'),
      DataTimePicker: () =>
        import('@/components/DataTimePicker/DataTimePicker.vue'),
      AnalyticsReadingNow: () =>
        import('@/components/AnalyticsReadingNow/AnalyticsReadingNow.vue'),
      AnalyticsCompareTraffic: () =>
        import(
          '@/components/AnalyticsCompareTraffic/AnalyticsCompareTraffic.vue'
        ),
      AnalyticsCompareChart: () =>
        import('@/components/AnalyticsCompareChart/AnalyticsCompareChart.vue'),
      EditorContainer: () =>
        import('@/components/EditorContainer/EditorContainer.vue'),
      ExpandAutocomplete: () =>
        import('@/components/ExpandAutocomplete/ExpandAutocomplete.vue'),
      NewContentStat: () =>
        import('@/components/NewContentStat/NewContentStat.vue'),
      AllArticleStat: () =>
        import('@/components/AllArticleStat/AllArticleStat.vue'),
      TopArticleStat: () =>
        import('@/components/TopArticleStat/TopArticleStat.vue'),
      StatLayout: () => import('@/components/StatLayout/StatLayout.vue'),
      ContenteditableElement: () =>
        import(
          '@/components/ContenteditableElement/ContenteditableElement.vue'
        ),
      DateTime: () => import('@/components/DateTime/DateTime.vue'),
      VCropper: () => import('@/components/VCropper/VCropper.vue'),
      LogoutAlert: () => import('@/components/LogoutAlert/LogoutAlert.vue'),
      UnknowAlert: () => import('@/components/UnknowAlert/UnknowAlert.vue'),
      ArticleAlert: () => import('@/components/ArticleAlert/ArticleAlert.vue'),
      ArticlePublishAlert: () =>
        import('@/components/ArticlePublishAlert/ArticlePublishAlert.vue'),
      DownloadedButton: () =>
        import('@/components/DownloadedButton/DownloadedButton.vue'),
      ArticleImageWidget: () =>
        import('@/components/ArticleImageWidget/ArticleImageWidget.vue'),
      InfiniteScroll: () =>
        import('@/components/InfiniteScroll/InfiniteScroll.vue'),
      MaskInput: () => import('@/components/MaskInput/MaskInput.vue'),
      Topbar: () => import('@/components/Topbar/Topbar.vue'),
      Tooltip: () => import('@/components/Tooltip/Tooltip.vue'),
      FormField: () => import('@/components/FormField/FormField.vue'),
      BaseCombobox: () => import('@/components/BaseCombobox/BaseCombobox.vue'),
      DatePicker: () => import('@/components/DatePicker/DatePicker.vue'),
      SimpleEditor: () => import('@/components/SimpleEditor/SimpleEditor.vue'),
    }

    components.ImageUi()
    components.ArticleImageWidget()
    components.ImageCropper()
    components.Tooltip()

    Object.entries(components).forEach(([id, constructor]) => {
      Vue.component(id, constructor)
    })
  },
}
